<template >
<v-parallax :src="require('@/assets/images/auth_background.png')" height="1000" class="login-container">
  <v-card class="login-card">
    <v-card-title>
      <!-- <v-img :src="Images.logo" class="login-logo" max-height="60" max-width="120"></v-img> -->
    </v-card-title> 
    <v-card-text ><!--style="padding-top: 30px"-->
      <v-container style="padding: 20px">
        <v-row>
          <v-text-field v-model="user.userPhone" placeholder="邮箱/手机号/用户名" prepend-inner-icon="mdi-email-outline"></v-text-field>
        </v-row>
        <v-row>
          <v-text-field v-model="user.userPassword" type="password" placeholder="密码" prepend-inner-icon="mdi-lock-open-outline" @keydown.enter="signin"></v-text-field>
        </v-row>
        <v-row class="align-center">
          <v-col>
            <v-checkbox label="自动登录"></v-checkbox>
          </v-col>
          <v-col>
            <v-btn small elevation="0"
            
            color="primary"
            dark
            @click="forgetPass"
            >忘记密码 </v-btn>
            
          </v-col>
          <v-col>
            <v-btn :style="{display:'block',marginBottom:'20px'}"  :to="{ path: 'signup' }" class="" rounded>
              <span>还没账号？立即注册</span>
              <span class="arrow-right"></span>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-btn block color="primary" @click="signin">登录</v-btn>
        </v-row>

        <!--忘记密码弹层-->
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >

        
        <v-row justify="center">
          <v-dialog
            v-model="dialog"
            persistent
            max-width="600px"
          >
            <!-- <template >
              <v-btn
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
              >
                Open Dialog
              </v-btn>
            </template> -->
            <v-card>
              <v-card-title>
                <span class="text-h5">找回密码</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    
                    
                    
                    <v-col cols="12">

                      <v-text-field
                        v-model="email"
                        :rules="emailRules"
                        label="请输入登录时的邮箱-Email*"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="9">
                      <v-text-field
                        label="图形验证码* (区分大小写)"
                        type="text"
                        required
                        v-model="icode"
                        :rules="codeRules"
                        :counter="4"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="3">
                      <span @click="refreshCode" style="cursor: pointer;">
                        <Identify :identifyCode="identifyCode" ></Identify>
                      </span>
                    </v-col>
                    
                  </v-row>
                </v-container>
                
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="dialog = false"
                >
                  取消
                </v-btn>
                <!--@click="dialog = false"-->
                <v-btn
                  color="blue darken-1"
                  text
                  
                  @click="validate"
                >
                  确定
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-form>
        <!--忘记密码弹层 end-->


				<!-- <v-row class="d-flex align-center"> 
          <v-text-field v-model="ssoUserName" placeholder="加密用户名" ></v-text-field>
					<v-btn  color="secondary" @click="ssoSignIn">SSO登录</v-btn>
        </v-row> -->

      </v-container>
    </v-card-text>
  </v-card>

</v-parallax>




</template>
<script>
import { mapActions } from 'vuex'
import { Routes } from '@/constants/routes'
import { constant } from '@/constants/constant.js'
import Images from '@/constants/image.js'
import Identify from './Identify.vue'
import axios from "axios";
import endpoints from "@/config/endpoints";

export default {
   components: { Identify },
  data() {
    return {
      dialog: false,   //忘记密码弹层
      email: '',
      icode: '',  //验证码
      emailRules: [
        v => !!v || 'E-mail 为必填项',
        v => /.+@.+\..+/.test(v) || 'E-mail 为必填项且格式必须正确',
      ],
      codeRules:[
        v => !!v || '验证码为必填项',
        v =>  (v && v.length <= 4) || '验证码为4个字符以内',
        v => (v && v === this.identifyArr[3]) || '验证码的值不正确'
      ],

      // 图片验证码
      identifyCode: '',
      // 验证码规则
      identifyCodes: '3456789ABCDEFGHGKMNPQRSTUVWXY',

      identifyArr:[], //获取图片验证码字符串

      user: {
        userPhone: '',
        userPassword: '',
      },
			ssoUserName:'', 
      Images: Images
    }
  },
	created(){
		if(this.$route.query.username){
			this.ssoLoginUser(this.$route.query.username)
      .then(() => window.location.href = `${Routes.OKR_LIST_PAGE}/${constant.pageCount}/1`)
		}
    this.makeCode(this.identifyCodes, 4);
    //console.log(this.makeCode(this.identifyCodes, 4),'this.makeCode')
	},
  methods: {
    ...mapActions("auth", ["loginUser","ssoLoginUser"]),

    ...mapActions('app', ['setNotification']),    //公共弹层方法
    signin(){
      this.loginUser(this.user)
      .then(() => window.location.href = `${Routes.OKR_LIST_PAGE}/${constant.pageCount}/1`)
    },
		ssoSignIn(){
      this.ssoLoginUser(this.ssoUserName)
      .then(() => window.location.href = `${Routes.OKR_LIST_PAGE}/${constant.pageCount}/1`)
    },
    //忘记密码
    forgetPass(){
      this.dialog = true;
    },
 
    //保存按钮
    validate () {
      if(this.$refs.form.validate()){

        this.axios.post(endpoints.API_URL+"/resetpwd", {
              email: this.email,
              
          }).then(res => {
            //console.log(res,'res结果')
            this.setNotification({
              status: 'success',
              text: res.message
            });
            this.dialog = false;
          })
          .catch(error => {
            //console.log(error.message);
            this.setNotification({
              status: 'warning',
              text: error.message
            });
          })

      }
      

    },

    // 切换验证码
    refreshCode() {
      this.identifyCode = '';
      this.makeCode(this.identifyCodes, 4)
    },

    // 生成随机验证码
    makeCode(o, l) {
      
      for (let i = 0; i < l; i++) {

        this.identifyCode += this.identifyCodes[
          Math.floor(Math.random() * (this.identifyCodes.length - 0) + 0)
        ]
        this.identifyArr.push(this.identifyCode);
      }
      //console.log(this.identifyArr[3],'identifyArr');
    },


  }
};
</script>

<style>
.login-container {
  height: 100% !important;
  position: relative;
}

.login-card {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 400px;
  height: 400px;
}

.login-logo {
  position: absolute;
  top: 10px;
  left: 120px;
}

.routeBtn {
  position: relative !important;
  top: 235px;
  left: calc(50% - 75px);
  width: 150px;
  height: 30px !important;
  background-color: rgba(10, 19, 26, .6) !important;
  font-size: 12px !important;
  line-height: 30px !important;
  color: #acb7bf !important;
  padding: 0 10px !important;
  transition: box-shadow .2s ease-in, color .2s ease;
}

.arrow-right {
  position: relative;
  top: 2px;
  width: 16px;
  height: 17px;
  background: url(//st-web.tita.com/titacn/tita/register/images/arraw-right.png) no-repeat;
}
</style>
